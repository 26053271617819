@import "https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;700&family=Sen:wght@400;700&display=swap";
:root {
  --color-neutral-dark: #000c;
  --color-neutral-medium: #0000003f;
  --font: "Sen", sans-serif;
}

* {
  box-sizing: border-box;
  font-family: var(--font);
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  display: flex;
  overflow-y: hidden;
}

.cards {
  height: 100vh;
  display: flex;
  overflow-x: hidden;
}

.cards > div {
  min-width: 100vw;
  scroll-snap-align: start;
  place-items: center;
  display: grid;
}

.cards p {
  max-width: 20ch;
  font-size: 1.5rem;
  font-weight: bold;
}

svg {
  position: fixed;
  bottom: 50px;
  right: 50px;
}

/*# sourceMappingURL=index.1e47f644.css.map */
