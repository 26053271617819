@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;700&family=Sen:wght@400;700&display=swap");

:root {
  --color-neutral-dark: #000000cc;
  --color-neutral-medium: #0000003f;

  --font: "Sen", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font);
  user-select: none;
}

body {
  height: 100vh;
  display: flex;
  overflow-y: hidden;
}

.cards {
  display: flex;

  overflow-x: hidden;
  /* width: 100vw; */
  height: 100vh;
}

.cards > div {
  min-width: 100vw;
  display: grid;
  place-items: center;
  scroll-snap-align: start;
}

.cards p {
  max-width: 20ch;
  font-weight: bold;
  font-size: 1.5rem;
}

svg {
  position: fixed;
  right: 50px;
  bottom: 50px;
}
